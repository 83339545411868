import React, { Component } from "react";
import { Header, Table, Button, Confirm } from "semantic-ui-react";

import "./ReviewSub.css";
import _ from "underscore";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
var axios = require('axios');


export default class ReviewSub extends Component {
  constructor(props) {
    super(props);
    this.state = { drafts: [], delconfirm:false, delmsg:"Please confirm you want to delete all samples in this submission." };
  }

  handleReceive(data){
  this.getSubmissions(data).then(
res => {
    if (res.status !== "ERROR")
  this.props.onDraftSelected(res);
}
  )
  }

  handleDeleteDraft=(data)=> {
    this.setState({delconfirm:true,delBody:data})
  }

  handledelconfirm = () => {
  this.DeleteSubmission(this.state.delBody).then(res=>{
      var newData=_.without(this.state.drafts, _.findWhere(this.state.drafts, {
        cust_id : this.state.delBody.cust_id,
        recv_dt : this.state.delBody.recv_dt,
        test_id : this.state.delBody.test_id
      }));
      this.setState({drafts:newData,delconfirm:false})  
  })
  .catch(err=>{this.setState({delconfirm:false})})
 }

 getSubmissions = async (data) => {
    const response = await fetch("/api/receiveSubmissios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({userid:this.props.userid,payload:data})
    });
    const body = await response.json();
    console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  editSubmission = (data) => {
    axios.post("/api/resetSubmissionsByName",{payload:data}).then(result => {

      if (result.data.status !== "ERROR") 
      this.props.onReset();
 
      // else {  this.errorpop.current.handleOpen();
      //   console.log("error" + JSON.stringify(result));
      // }
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  DeleteSubmissionByName = async (data) => {
    axios.post("/api/delSubmissionsByName",{payload:data}).then(result => {

      if (result.data.status !== "ERROR") 
      this.getAllSubmission().then(res => {
        if (res.status !== "ERROR") 
         { 
          var testOptions = this.state.testOptions;
          // console.log(data);
          for (var i in res) 
          { res[i].TEST=(_.map(res[i].test_id.split(","), function(id){ return _.where(testOptions, {
             value: id
           })[0].text})).join(", ");
          }
          this.setState({ drafts: res });
    }
      // console.log(res);
    });
      // else {  this.errorpop.current.handleOpen();
      //   console.log("error" + JSON.stringify(result));
      // }
    })
    .catch(function(error) {
      console.log(error);
    });
  };

 
   getAllSubmission = async () => {
    const response = await fetch("/api/getSubmission?custid="+this.props.custid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
     console.log(body);

    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentWillMount() {
    this.getAllTests().then(res => { 
    this.setState({ testOptions: res });
    this.getAllSubmission().then(res => {
        if (res.status !== "ERROR") 
         { 
          var testOptions = this.state.testOptions;
          // console.log(data);
          for (var i in res) 
          { res[i].TEST=(_.map(res[i].test_id.split(","), function(id){ return _.where(testOptions, {
             value: id
           })[0].text})).join(", ");
          }
          this.setState({ drafts: res });
    }
      // console.log(res);
    });
  })
  }

  getForm = async(filename) => {
    const response = await fetch("/api/getPDF?filename="+filename, {
        method: "GET",
        headers: {
          Accept: "application/blob",
          "Content-Type": "application/json"
        }
    })
    const body = await response.blob();
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    return body;
  }
  
  downloadForm = (filename) => {
    this.getForm(filename).then(
      res=>{
  
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
          const fileURL = URL.createObjectURL(res);
          var anchor = document.createElement('a');
          document.body.appendChild(anchor);
          anchor.setAttribute("type", "hidden");
          anchor.download = filename;
          anchor.href=fileURL;
          anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
           anchor.click();
      }
      }
  )
  }

  render() {
    var databody = this.state.drafts.map((data, index) => {
        return (
          <Table.Row key={"c" + index}>
            <Table.Cell>{data.CUST_NM}</Table.Cell>
            <Table.Cell>{data.po_number}</Table.Cell>
            <Table.Cell>{data.recv_dt}</Table.Cell>
            <Table.Cell>{data.TEST}</Table.Cell>
            <Table.Cell>{data.cnt}</Table.Cell>
            <Table.Cell>{data.rpt_nm}
            {data.rpt_nm?<Button color='blue' className='dlbutton' size='mini' onClick={() => this.downloadForm(data.rpt_nm)}>
                {" "}
               Download{" "}
              </Button> :''}
              {data.rpt_nm?<Button color='red' className='dlbutton' size='mini' onClick={() => this.DeleteSubmissionByName(data.rpt_nm)}>
                {" "}
               Delete{" "}
              </Button> :''}
              {data.rpt_nm?<Button color='green' className='dlbutton' size='mini' onClick={() => this.editSubmission(data.rpt_nm)}>
                {" "}
               Update{" "}
              </Button> :''}
              </Table.Cell>
            {/* <Table.Cell>
              <Button onClick={() => this.handleReceive(data)}>
                {" "}
               Receive{" "}
              </Button>
              <Button onClick={() => this.handleDeleteDraft(data)}>
                {" "}
                Delete Submission{" "}
              </Button>
            </Table.Cell> */}
          </Table.Row>
        );
      });

    return (
        <div>
          <Header as="h2"> Please find all submitted requests below </Header>
        <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell>PO Number</Table.HeaderCell>
            <Table.HeaderCell>Submission Date</Table.HeaderCell>
            <Table.HeaderCell>Test</Table.HeaderCell>
            <Table.HeaderCell>Sample Count</Table.HeaderCell>
            <Table.HeaderCell>Submission Form</Table.HeaderCell>
            {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
      {databody}
     </Table.Body>
      </Table>
      <Confirm open={this.state.delconfirm} content={this.state.delmsg} 
        onCancel={()=>{this.setState({delconfirm:false})}} onConfirm={()=>this.handledelconfirm()} />
        </div>
    );
  }
}
